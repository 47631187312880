import React, { useRef, useState } from 'react';
import * as hms from './header_m.module.scss';
import LogoBlack from '@images/ic_logo_black.png';
// import LogoWhite from '@images/ic_logo_white.png';
import LogoB from '@images/logo_b.svg';
import Menu from './MenuM';
import Profile from './ProfileM';
import { isLogin } from '@service/AccountService';
import SearchIcon from '@images/ic_search.svg';
import SearchInput from '@modules/search/searchInput';
import { isActivePath } from '@lib/utils';
import { useMediaQuery } from 'react-responsive';

const pageClassMap = new Map([['HOME', hms.pHome], ['OUTLET', hms.pOutlets], ['LUX', hms.pLux], ['ECO', hms.pEco], ['PRODUCT', hms.pProduct]])

const HeaderM = (props) => {
  const { pageName } = props || {}
  const [visible, setVisible] = useState(false)
  const [visibleAvatar, setVisibleAvatar] = useState(false)
  const headerRef = useRef(null)
  const [searchInputVisible, setSearchInputVisible] = useState(false)
  const isCheckout = pageName === 'CHECKOUT'
  const isMobileScreen = useMediaQuery({
    query: "(max-width: 960px)",
  })

  const getPageClass = () => {
    return pageClassMap.get(pageName) || `${pageName}-header-m`
  }

  const clickAvatar = () => {
    if(isLogin()) {
      // 显示个人信息框
      setVisibleAvatar(true)
    } else {
      // 跳转登陆
      window.location.href = '/login'
    }
  }

  function clickMenu (path) {
    setVisible(false)
    if (path) {
      window.location.href = path
    }
  }

  function clickSearchInput(e) {
    e.preventDefault()
    setSearchInputVisible(true)
  }

  function isEURO() {
    return props.pageName === 'EURO'
  }

  return (
    <header className={`${getPageClass()} `} ref={headerRef}>
      <div className={hms.headerM}>
        {isCheckout ? <div className={hms.headerLeftPlaceholder}></div> : <div className={hms.headerMImg} aria-label="menu icon" onClick={() => { setVisible(!visible) }}></div>}
        {
          (isActivePath('/prod/') || isEURO()) ? (
            <a href='/search' key='search-input' title='search-input' className={hms.searchGrey} onClick={clickSearchInput}>
              <img src={SearchIcon} alt='search-icon' />
            </a>
          ) : null
        }
        {isCheckout ? (
          <div className={hms.checkoutHeaderTitle}>
            <img src={LogoB} />Check Out
          </div>
        ): (
          <div className={hms.headerTitle}>
            <a href='/' aria-label='homepage' title='homepage'>
                <img className={hms.logo} alt='logo' src={LogoBlack} />
            </a>
          </div>
        )}
        <div onClick={clickAvatar} className={hms.headerMProfile} title="profile icon" ></div>
      </div>

      {isMobileScreen ? (
        <Menu clickMenu={clickMenu} visible={visible} headerHeight={headerRef?.current?.offsetHeight} close={() => setVisible(false)} />
      ) : null}
      <Profile visible={visibleAvatar} close={() => setVisibleAvatar(false)} />
      <SearchInput visible={searchInputVisible} setVisible={setSearchInputVisible} />
    </header>
  )
}

export default HeaderM